export const ON_CHANGE_DEBOUNCE_TIMER = 300;

export const PUB_SUB_EVENTS = {
  cartUpdate: 'cart-update',
  quantityUpdate: 'quantity-update',
  variantChange: 'variant-change',
  cartError: 'cart-error',
};

export function headerHeight() {
  const header = document.querySelector('header');
  const announcementBar = document.querySelector('announcement-slider');
  let height = 80;
  if(header && announcementBar) {
    height = header.clientHeight + announcementBar.clientHeight
  } else if(header && !announcementBar) {
    height = header.clientHeight;
  }
  document.documentElement.style.setProperty('--header-height', height + "px"  );
  return height;
}

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty(`--app-height`, `${window.innerHeight}px`)
 }
 window.addEventListener(`resize`, appHeight)
 appHeight()
headerHeight();

